import React, {useEffect, useState} from 'react';
import {
    Box,
    FormControl,
    Grid,
    OutlinedInput,
    Button,
    Typography,
    Paper,
    MenuItem,
    Menu,
    Card,
    Container,
    Link,
    Stack,
    TextField,
    alpha,
    TextareaAutosize,
    IconButton,
    Table,
    TableBody ,
    TableHead,
    TableCell ,
    TableRow ,
    TableContainer ,
    

  } from "@mui/material";
import Logo from "../assets/logoi.png";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FaWhatsapp } from 'react-icons/fa';


const Services = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/7795102277');
  };


  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    // Check scroll position on component mount
    checkScrollTop();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);

  const services = [
    {
      id: 1,
      name: "GST REGISTRATION for PROPRIETORSHIP FIRM",
      price: "₹2,000"
    },
    {
      id: 2,
      name: "GST REGISTRATION for PARTNERSHIP FIRM",
      price: "₹2,500"
    },
    {
      id: 3,
      name: "GST REGISTRATION for LP, LLP, PVT LTD, OPC PVT LTD COMPANY",
      price: "₹3,000"
    },
    {
      id: 4,
      name: "PARTNERSHIP DEED with ₹2000 e-stamp paper, PAN CARD & COMPANY SEAL",
      price: "₹5,000"
    },
    {
      id: 5,
      name: "GST NILL FILING",
      price: "₹400/Month"
    },
    {
      id: 6,
      name: "GST BILL FILING (UP TO 20 SALE INVOICES)",
      price: "₹800/Month"
    },
    {
      id: 7,
      name: "GST BILL FILING (ABOVE 20 SALE INVOICES)",
      price: "₹1,000/Month with excel. ₹2,000/Month without excel."
    },
    {
      id: 8,
      name: "E WAY BILL for a INVOICE",
      price: "₹200"
    },
    {
      id: 9,
      name: "GST AMENDMENTS",
      price: "₹500"
    },
    {
      id: 10,
      name: "INCOME TAX RETURNS FILING",
      price: "PRICE DIFFERS"
    },
    {
      id: 11,
      name: "INCOME TAX RETURNS FILING (FOR 3 YEARS BELOW 5 LAKHS)",
      price: "₹6,000 excluding Late fee"
    },
    {
      id: 12,
      name: "ANNUAL PROFESSION TAX FILING",
      price: "₹500/YR"
    },
    {
      id: 13,
      name: "FSSAI BASIC REGISTRATION",
      price: "₹2,500/YR"
    },
    {
      id: 14,
      name: "FSSAI STATE REGISTRATION (FOR MERCHANT AND SERVICE PROVIDER)",
      price: "₹6,000/YR"
    },
    {
      id: 15,
      name: "FSSAI STATE REGISTRATION (FOR MANUFACTURER AND REPACKER)",
      price: "₹12,000/YR"
    },
    {
      id: 16,
      name: "FSSAI CENTRAL REGISTRATION (FOR MERCHANT AND IMPORT/EXPORT)",
      price: "₹24,000/YR For Proprietorship Firm. ₹25,000/YR For Partnership Firm & Company."
    },
    {
      id: 17,
      name: "FSSAI RENEWALS BASIC",
      price: "₹1,000/YR"
    },
    {
      id: 18,
      name: "FSSAI RENEWALS STATE",
      price: "₹3,000/YR"
    },
    {
      id: 19,
      name: "FSSAI RENEWALS STATE – MANUFACTURER & REPACKER",
      price: "₹6,000/YR"
    },
    {
      id: 20,
      name: "SHOP & ESTABLISHMENT REGISTRATION (FOR 0-9 EMPLOYEES) or RENEWAL",
      price: "₹3,800"
    },
    {
      id: 21,
      name: "SHOP & ESTABLISHMENT REGISTRATION (FOR 10-19 EMPLOYEES) or RENEWAL",
      price: "₹12,800"
    },
    {
      id: 22,
      name: "MSME (UDYAM) REGISTRATION CERTIFICATE",
      price: "₹1,000"
    },
    {
      id: 23,
      name: "IMPORT EXPORT CODE",
      price: "₹2,000"
    },
    {
      id: 24,
      name: "TRADE MARK REGISTRATION",
      price: "₹12,000 excluding Objection fees, etc.,"
    },
    {
      id: 25,
      name: "ISO REGISTRATION",
      price: "PRICE DIFFERS"
    },
    {
      id: 26,
      name: "ESI AND PF REGISTRATION",
      price: "PRICE DIFFERS"
    },
    {
      id: 27,
      name: "FIRM REGISTRATION",
      price: "PRICE DIFFERS"
    },
    {
      id: 28,
      name: "COMPANY REGISTRATION PRIVATE LIMITED, LLP, OPC.",
      price: "PRICE DIFFERS"
    }
  ];
  

  


  return (
   <Box >
    <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
        <Box
          id="hero"
          sx={(theme) => ({
            width: "100%",
            backgroundImage:
              theme.palette.mode === "light"
                ? "linear-gradient(180deg, #CEE5FD, #FFF)"
                : `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
            backgroundSize: "100% 20%",
            backgroundRepeat: "no-repeat",
            // backgroundColor:"#f7f9fb",
            '@media only screen and (max-width: 500px) and (min-width: 300px)': { background:"none" },
          })}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: { xs: 14, sm: 20 },
              pb: { xs: 8, sm: 12 },
            }}
          >
            <Stack
              spacing={2}
              useFlexGap
              sx={{ width: { xs: "100%", sm: "70%" } }}
            >
              <Typography
                variant="h1"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignSelf: "center",
                  textAlign: "center",
                  fontSize: "clamp(3.5rem, 10vw, 4rem)",
                  fontFamily: "JostMedium",
                }}
              >
                Enlighting&nbsp;
                <Typography
                  component="span"
                  variant="h1"
                  sx={{
                    fontSize: "clamp(3rem, 10vw, 4rem)",
                    fontFamily: "JostMedium",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "#531692"
                        : "primary.light",
                  }}
                >
                  endeavours
                </Typography>
              </Typography>
              <Typography
                textAlign="center"
                color="text.secondary"
                sx={{
                  alignSelf: "center",
                  fontSize: "17px",
                  width: { sm: "100%", md: "80%" },
                  fontFamily: "JostMedium",
                }}
              >
                Experience our tailored solutions, designed to meet your needs
                with top-tier features for an exceptional experience.{" "}
              </Typography>
              <center>
                <img src={Logo} style={{ width: "290px", height: "auto" }} />
              </center>
              <Typography
                variant="body2"
                textAlign="center"
                sx={{ opacity: 0.8, fontFamily: "JostMedium" , fontSize:"13px" , letterSpacing:"1px" }}
              >
                Nurturing Potential, Realizing Opportunities, Sustaining Success.
              </Typography>
            </Stack>
           
          </Container>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{display:"flex" , justifyContent:"center"}} >
        <Box>
        <Typography variant='h4'  sx={{textAlign:"center" , fontFamily:"JostMedium",}}>Our Services</Typography>
        <br/>
        <Typography variant='body2'  sx={{textAlign:"center" , fontFamily:"JostMedium", fontSize:"20px"}}>(As on for Assessment Year 2024-2025)</Typography>


        </Box>
       
      </Grid>
      <br/> <br/>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Container>
          <Box>

          <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650, border: '2px solid #531692' }} aria-label="simple table">
    <TableHead>
      <TableRow sx={{ height: "120px" ,             '@media only screen and (max-width: 500px) and (min-width: 300px)': { height:"50px" },
}}>
        <TableCell align='center' style={{ border: '2px solid #531692' , fontSize:"20px" , fontFamily:"JostMedium" }}>S.No</TableCell>
        <TableCell align='center' style={{ border: '2px solid #531692' , fontSize:"20px" , fontFamily:"JostMedium" }}>Particulars</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {services.map((row , index)=>{
        return (
          <>
             <TableRow sx={{ height: "120px" , '@media only screen and (max-width: 500px) and (min-width: 300px)': { height:"50px" }, }} key={index}>
        <TableCell align='center' style={{ border: '2px solid #531692' , fontSize:"17px" , fontFamily:"JostMedium" , }}>{row.id}</TableCell>
        <TableCell align='center'style={{ border: '2px solid #531692' , fontSize:"17px" , fontFamily:"JostMedium"  }}>{row.name}</TableCell>
      </TableRow>
          </>
        )
      })}
   
    </TableBody>
  </Table>
</TableContainer>

          </Box>
     

        </Container>
     
      </Grid>
      <Button
        onClick={handleWhatsAppClick}
        variant="contained"
        // color="primary"
        style={{
          position: 'fixed',
          bottom: '80px',
          right: '20px',
          zIndex: '99',
          backgroundColor:"green",
          color:"white" ,

        }}
      >
        <FaWhatsapp fontSize={"30px"} />
      </Button>
<br/>
        <Button
        onClick={scrollTop}
        // variant="contained"
        // color="#531692"
        style={{
          display: showScroll ? 'flex' : 'none',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: '99',
          color:"white",
          backgroundColor:"#531692"
        }}
      >
        <KeyboardArrowUpIcon />
      </Button>
   </Box>
  )
}

export default Services
