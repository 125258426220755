import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Header from "../components/header/header";
import Footer from "../components/Footer/footer";
import Services from "../pages/services";


const AppRoute = () => {
  return (
    <Router>
      <div>
        <Header />
      </div>
      <div>
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/services" element={<Services />} />
        </Routes>
      </div>
      <div>
        <Footer />
      </div>
    </Router>
  );
};

export default AppRoute;
