import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  OutlinedInput,
  Button,
  Typography,
  Paper,
  MenuItem,
  Menu,
  Card,
  Container,
  Link,
  Stack,
  TextField,
  alpha,
  TextareaAutosize,
  IconButton,
} from "@mui/material";
// import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import success from "../assets/success.png";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import Hero from "./Hero";
import twomens from "../assets/twomens.jpg";
import payroll from "../assets/icons/payroll.png";
import gst from "../assets/icons/gst.png";
import incometax from "../assets/icons/income tax.png";
import accounting from "../assets/icons/accounting.png";
import trademark from "../assets/icons/trademark.png";
import business from "../assets/icons/business.png";
import "../App.css";
import Logo from "../assets/logoi.png";
import india from "../assets/india.png";
import mission from "../assets/mission.jpg";
import hands from "../assets/hands.png";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FaWhatsapp } from 'react-icons/fa';
import TwoHands from '../assets/twohands.png'
const Dashboard = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/7795102277');
  };





  return (
    <Box >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
        <Box
          id="hero"
          sx={(theme) => ({
            width: "100%",
            backgroundImage: "linear-gradient(179.87deg, #CEE5FD, #FFF)",
            backgroundSize: "100% 20%",
            backgroundRepeat: "no-repeat",
            // backgroundColor:"#f7f9fb",
            '@media only screen and (max-width: 500px) and (min-width: 300px)': { background:"none" },
          
          })}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: { xs: 14, sm: 20 },
              pb: { xs: 8, sm: 12 },
            }}
          >
            <Stack
              spacing={2}
              useFlexGap
              sx={{ width: { xs: "100%", sm: "70%" } }}
            >
              <Typography
                variant="h1"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignSelf: "center",
                  textAlign: "center",
                  fontSize: "clamp(3.5rem, 10vw, 4rem)",
                  fontFamily: "JostMedium",
                }}
              >
                Enlighting&nbsp;
                <Typography
                  component="span"
                  variant="h1"
                  sx={{
                    fontSize: "clamp(3rem, 10vw, 4rem)",
                    fontFamily: "JostMedium",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "#531692"
                        : "primary.light",
                  }}
                >
                  endeavours
                </Typography>
              </Typography>
              <Typography
                textAlign="center"
                color="text.secondary"
                sx={{
                  alignSelf: "center",
                  fontSize: "17px",
                  width: { sm: "100%", md: "80%" },
                  fontFamily: "JostMedium",
                }}
              >
                Experience our tailored solutions, designed to meet your needs
                with top-tier features for an exceptional experience.{" "}
              </Typography>
              <center>
                <img src={Logo} style={{ width: "290px", height: "auto" }} />
              </center>
              <Typography
                variant="body2"
                textAlign="center"
                sx={{ opacity: 0.8, fontFamily: "JostMedium" , fontSize:"13px" , letterSpacing:"1px" }}
              >
                Nurturing Potential, Realizing Opportunities, Sustaining Success.
              </Typography>
            </Stack>
           
          </Container>
        </Box>

        <Grid
          item
          xs={12}
          lg={12}
          md={12}
          sm={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ maxWidth: "100%" }}>
            <img
              src={india}
              alt="banner"
              style={{ borderRadius: "13px", maxWidth: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
      <br /> <br />
      <Grid
        container
        spacing={2}
        sx={{
          background: "#f7f9fb",
          height: "auto",
          padding: "18px 40px",
          display: "flex",
          justifyContent: "center",
          marginLeft:"-8px"
        }}
      >
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              letterSpacing: "1px",
              fontFamily: "JostMedium",
            }}
          >
            Explore our digital services
          </Typography>
          <br />
          <Typography
            variant="body2"
            style={{
              textAlign: "center",
              letterSpacing: "1px",
              fontSize: "19px",
              padding: "10px",
              lineHeight: "38px",
              fontFamily: "JostMedium",
              '@media only screen and (max-width: 720px) and (min-width: 300px)': { fontSize:"16px" },
            }}
          >
            Grow your business and remain compliance by partnering with Seedan
            for a range of high quality, business services from incorporation to
            payroll delivered through a seamless online platform.
          </Typography>
        </Grid>
        <Grid
          item
          lg={2}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <center>
            <Box
              className="card_item"
              style={{
                width: "200px",
                heigth: "200px",
                borderRadius: "4%",
                padding: "15px",
                background: "white",
                outline: "1px solid grey",
              }}
            >
              <Grid>
                
                <img src={business} alt="Business" style={{ height: "65px" }} />
              </Grid>
              <br />
              <Grid>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "20px",
                    letterSpacing: "1px",
                    fontFamily: "JostMedium",
                  }}
                >
                  Incorporation
                </Typography>
              </Grid>
            </Box>
          </center>
        </Grid>
        <Grid
          item
          lg={2}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <center>
            <Box
              className="card_item"
              style={{
                background: "white",
                width: "200px",
                heigth: "200px",
                borderRadius: "4%",
                padding: "15px",
                border: "none",
                outline: "1px solid grey",
              }}
            >
              <Grid>
                <img
                  src={trademark}
                  alt="trademark"
                  style={{ height: "65px" }}
                />
              </Grid>
              <br />
              <Grid>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "20px",
                    letterSpacing: "1px",
                    fontFamily: "JostMedium",
                  }}
                >
                  Trademark
                </Typography>
              </Grid>
            </Box>
          </center>
        </Grid>
        <Grid
          item
          lg={2}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <center>
            <Box
              className="card_item"
              style={{
                background: "white",
                width: "200px",
                heigth: "200px",
                borderRadius: "4%",
                padding: "15px",
                border: "none",
                outline: "1px solid grey",
              }}
            >
              <Grid>
                <img
                  src={accounting}
                  alt="accounting"
                  style={{ height: "65px" }}
                />
              </Grid>
              <br />
              <Grid>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "20px",
                    letterSpacing: "1px",
                    fontFamily: "JostMedium",
                  }}
                >
                  Accounting
                </Typography>
              </Grid>
            </Box>
          </center>
        </Grid>
        <Grid
          item
          lg={2}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <center>
            <Box
              className="card_item"
              style={{
                width: "200px",
                heigth: "200px",
                borderRadius: "4%",
                padding: "15px",
                border: "none",
                background: "white",
                outline: "1px solid grey",
              }}
            >
              <Grid>
                <img
                  src={incometax}
                  alt="incometax"
                  style={{ height: "65px" }}
                />
              </Grid>
              <br />
              <Grid>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "20px",
                    letterSpacing: "1px",
                    fontFamily: "JostMedium",
                  }}
                >
                  Income Tax
                </Typography>
              </Grid>
            </Box>
          </center>
        </Grid>
        <Grid
          item
          lg={2}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <center>
            <Box
              className="card_item"
              style={{
                width: "200px",
                heigth: "200px",
                borderRadius: "4%",
                padding: "15px",
                border: "none",
                background: "white",
                outline: "1px solid grey",
              }}
            >
              <Grid>
                <img src={gst} alt="gst" style={{ height: "65px" }} />
              </Grid>
              <br />
              <Grid>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "20px",
                    letterSpacing: "1px",
                    fontFamily: "JostMedium",
                  }}
                >
                  GST Services
                </Typography>
              </Grid>
            </Box>
          </center>
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <center>
            <Box
              className="card_item"
              style={{
                width: "200px",
                heigth: "200px",
                borderRadius: "4%",
                padding: "15px",
                border: "none",
                outline: "1px solid gray",
                background: "white",
              }}
            >
              <Grid>
                <img src={payroll} alt="payroll" style={{ height: "65px" }} />
              </Grid>
              <br />
              <Grid>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "20px",
                    letterSpacing: "1px",
                    fontFamily: "JostMedium",
                  }}
                >
                  Pay Roll
                </Typography>
              </Grid>
            </Box>
          </center>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item lg={2}></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid sx={{ maxWidth: "100%" }}>
            <img
              src={success}
              alt="success"
              style={{ padding: "5px", margin: "0 10px", maxWidth: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
        >
          <Grid>
            <Typography variant="h4" marginLeft={1} fontFamily={"JostMedium"} style={{  '@media only screen and (max-width: 720px) and (min-width: 300px)': { fontSize:"12px" },}}>
              Our Mission
            </Typography>
            <br />
            <Typography
              fontFamily={"JostMedium"}
              variant="body2"
              sx={{
                fontSize: "19px",
                padding: "5px",
                margin: "0 10px",
                letterSpacing: "2px",
                lineHeight: "35px",
                textAlign: "justify",
                '@media only screen and (max-width: 720px) and (min-width: 300px)': { fontSize:"14px" },
              }}
            >
               Our mission is to provide comprehensive financial and tax services that empower individuals and businesses to thrive. We believe in delivering personalized solutions that address the unique needs and goals of each client. With a commitment to integrity, excellence, and innovation, we strive to build lasting relationships based on trust and mutual success. 
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={2}></Grid>
       

        <Grid
          item
          xs={12}
          lg={12}
          md={12}
          sm={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ maxWidth: "100%" }}>
          <br/>
          <br/>
            <img
              src={twomens}
              alt="twomens"
              style={{ borderRadius: "13px", maxWidth: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
      <br /> <br />
      <Grid container spacing={2}>
          <Grid item lg={2}></Grid>
         
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
          >
            <Grid>
              <Typography variant="h4" marginLeft={1} fontFamily={"JostMedium"} >
                About Us
              </Typography>
              <br />
              <Typography
                fontFamily={"JostMedium"}
                variant="body2"
                sx={{
                  fontSize: "19px",
                  padding: "5px",
                  margin: "0 10px",
                  letterSpacing: "2px",
                  lineHeight: "35px",
                  textAlign: "justify",
                  '@media only screen and (max-width: 720px) and (min-width: 300px)': { fontSize:"14px" },
                }}
              >
                Welcome to Seedan Group! We're your go-to experts for license
                registration, tax filing, accounting, and business advisory
                services. Our mission is simple: to help you build a successful
                business without the hassle. With personalized solutions and a
                commitment to your success, Seedan Group is the partner you can
                trust for worry-free business management.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid sx={{ maxWidth: "100%" }}>
              <img
                src={TwoHands}
                alt="TwoHands"
                style={{ padding: "5px", margin: "0 10px", maxWidth: "80%" }}
              />
            </Grid>
          </Grid>
          <Grid item lg={2}></Grid>

          <Grid
            item
            lg={12}
            sm={12}
            md={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container>
              <Box
                id="image"
                sx={(theme) => ({
                  mt: { xs: 8, sm: 10 },
                  alignSelf: "center",
                  height: "auto",
                  width: "100%",
                  backgroundImage:
                    theme.palette.mode === "light"
                      ? 'url("/static/images/templates/templates-images/hero-light.png")'
                      : 'url("/static/images/templates/templates-images/hero-dark.png")',
                  backgroundSize: "cover",
                  borderRadius: "10px",
                  outline: "1px solid",
                  outlineColor:
                    theme.palette.mode === "light"
                      ? alpha("#BFCCD9", 0.5)
                      : alpha("#9CCCFC", 0.1),
                  boxShadow:
                    theme.palette.mode === "light"
                      ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                      : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
                      
                })}
              >
                <Grid container spacing={2} >
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Box sx={{ padding: "40px" }}>
                      <Typography variant="h3" fontFamily={"JostMedium"}>
                        Contact Us
                      </Typography>
                      <Typography variant="body2" fontFamily={"JostMedium"}>
                        Feel Free to contact us any time. We will get back to
                        you as soon as we can!.
                      </Typography>
                      <TextField
                        id="standard-search"
                        label="Name"
                        type="name"
                        variant="standard"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        id="standard-search"
                        label="Email"
                        type="email"
                        variant="standard"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        id="standard-search"
                        label="Message"
                        type="text"
                        variant="standard"
                        fullWidth
                        margin="normal"
                      />
                      <br /> <br />
                      <Button  className="send_button">Send</Button>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={12}
                    md={6}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "40px",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        variant="h3"
                        fontFamily="JostMedium"
                        sx={{ textAlign: "center" }}
                      >
                        Contact Info
                      </Typography>
                      <br /> <br />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <HeadsetMicIcon />
                        <Typography
                          variant="body2"
                          fontFamily="JostMedium"
                          style={{ fontSize: "22px" }}
                        >
                          +91 77995102277
                        </Typography>
                      </div>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <EmailIcon />
                        <Typography
                          variant="body2"
                          fontFamily="JostMedium"
                          style={{ fontSize: "22px" }}
                        >
                          seedangroup@gmail.com
                        </Typography>
                      </div>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <LocationOnIcon />
                        <Typography
                          variant="body2"
                          fontFamily="JostMedium"
                          style={{ fontSize: "22px" }}
                        >
                          NO:849/5 , 1st cross street kacharkanhalli,behind
                          Amma's pastry , Banglore- 560084
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Grid>
        </Grid>

        <Button
        onClick={handleWhatsAppClick}
        variant="contained"
        // color="primary"
        style={{
          position: 'fixed',
          bottom: '80px',
          right: '20px',
          zIndex: '99',
          backgroundColor:"green",
          color:"white" ,

        }}
      >
        <FaWhatsapp fontSize={"30px"} />
      </Button>
<br/>
        <Button
        onClick={scrollTop}
        // variant="contained"
        // color="#531692"
        style={{
          display: showScroll ? 'flex' : 'none',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: '99',
          color:"white",
          backgroundColor:"#531692"
        }}
      >
        <KeyboardArrowUpIcon />
      </Button>
    </Box>
  );
};



function DashboardComponent() {
  return (
    <div
     id="dash_container"
     >
      <Dashboard />
    </div>
  );
}

export default DashboardComponent