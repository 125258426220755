import React from "react";
import AppRoute from "./AppRoutes/PageRoutes";
import Form2 from "./pages/form2";
import  AppBar from './components/header/header';
import Footer from './components/Footer/footer';
import Dashboard from './pages/Dashboard'
function App() {
  return (
    <div>
<AppRoute/>
    </div>
  )
}

export default App;
